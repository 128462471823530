

<template>
  <div id='checkoutView'>
    <v-container style="max-width: 1620px" :style="{'padding-inline': $vuetify.breakpoint.smAndDown ? '0px' : ''}">
      <!-- Breadcrumbs -->
       <v-breadcrumbs class="pb-10" :items="breadcrumbs"></v-breadcrumbs>
      <v-card class="rounded-card mt-0" :style="{'padding': $vuetify.breakpoint.smAndDown ? '0px' : '', 'overflow': $vuetify.breakpoint.smAndDown ? 'hidden': ''}">
        <v-row>
          <v-col class="col-md-8 col-sm-12 col-xs-12">
            <v-stepper v-model="step" vertical :style="{'box-shadow': 'none', 'padding-bottom': $vuetify.breakpoint.xsOnly ? '0px': ''}">
              <v-stepper-step :complete="step > 1" step="1"> Information </v-stepper-step>
              <v-stepper-content step="1">
                <!-- Shipping Information Form --> 
                <div style="margin: 5px;" ref="linkAuth"></div>
                <v-form @submit.prevent="validateStep1">

                  <v-btn
                    class="nextBtn"
                    :block="$vuetify.breakpoint.xsOnly"
                    color="primary"
                    @click="nextStep"
                    :disabled="step === 4 || !customer.email" rounded x-large
                    >PROCEED TO Shipping</v-btn
                  >
                </v-form>
              </v-stepper-content>

              <v-stepper-step :complete="step > 2" step="2">
                Shipping & Billing
              </v-stepper-step>
              <v-stepper-content step="2">
                <!-- Shipping Information Form -->
                <v-form @submit.prevent="validateStep1">
                  <!-- Your shipping information form fields go here -->
                  <!-- Example: -->
                  <div style="margin: 5px;" ref="addressElement"></div>
                  <!-- Add more fields as needed -->
         
                  
                    <v-expansion-panels v-if="appData.shippingPolicy.storewideRates.enabled" v-model="open" style="border-radius: 15px!important; padding: 5px;z-index:0;">
                      <v-expansion-panel style="border-radius: 15px!important;">
                        <v-expansion-panel-header>
                          <template v-slot:actions>
                            <v-icon color="primary">
                              $expand
                            </v-icon>
                          </template>
                        <template v-slot:default="{ open }">
                          <v-row no-gutters>
                            <v-col cols="4">
                              Shipping Method
                            </v-col>
                            <v-col
                              cols="8"
                              class="text--secondary"
                            >
                              <v-fade-transition leave-absolute v-if="selectedShippingMethod">
                                <span
                                  v-if="open"
                                  key="0"
                                >
                                {{ selectedShippingMethod.title }}
                                </span>
                                <span
                                  v-else
                                  key="1"
                                >
                                {{ selectedShippingMethod.title }}
                                </span>
                              </v-fade-transition>
                            </v-col>
                          </v-row>
                        </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <template>
                            <v-list rounded>
                              <v-subheader>Select Shipping Method</v-subheader>
                              <v-list-item-group mandatory
                                v-model="selectedShippingMethod"
                                color="primary"
                                @change="handleShippingMethodChange"
                              >
                                <v-list-item :disabled="shippingMethodChangeLoading" v-for="(method,i) in intentResponse?.shippingMethods" :key="i" :value="method">
                                  <v-list-item-icon>
                                    <v-icon v-if="!shippingMethodChangeLoading || selectedShippingMethod !== method" v-text="method.title == 'Priority Shipping' ? 'mdi-truck-fast' : 'mdi-truck'"></v-icon>
                                    <v-progress-circular v-else :size="20" indeterminate color="primary"></v-progress-circular>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>{{method.title}}</v-list-item-title>
                                    <v-list-item-subtitle>{{method.logisticAging}} business days</v-list-item-subtitle>
                                   
                                    
                                  </v-list-item-content>
                                  <v-list-item-action>${{method.logisticPrice}}</v-list-item-action>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </template>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                    <!-- <v-expansion-panels v-else accordion style="border-radius: 20px!important;">
                      <v-expansion-panel v-for="cartItem in [...cartItems].sort((a, b) => {
                          if (a.isDropShipped && !b.isDropShipped) {
                            return -1; // a comes before b
                          } else if (!a.isDropShipped && b.isDropShipped) {
                            return 1; // b comes before a
                          } else {
                            return 0; // a and b are equal, maintain original order
                          }
                        })" :disabled="!cartItem.isDropShipped">
                        <v-expansion-panel-header>
                          <template v-slot:actions>
                            <v-icon color="primary">
                              $expand
                            </v-icon>
                          </template>
                        <template v-slot:default="{ open }">
                          <v-row no-gutters>
                            <v-col cols="4">
                              {{ cartItem.name }}
                            </v-col>
                            <v-col
                              cols="8"
                              class="text--secondary"
                            >
                              <v-fade-transition leave-absolute>
                                <span
                                  v-if="open"
                                  key="0"
                                >
                                  Shipping Method
                                </span>
                                <span
                                  v-else
                                  key="1"
                                >
                                  {{ priorityShipping ? 'Priority Shipping' : 'Free Shipping' }}
                                </span>
                              </v-fade-transition>
                            </v-col>
                          </v-row>
                        </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <template>
                            <v-list rounded>
                              <v-subheader>Select Shipping Method</v-subheader>
                              <v-list-item-group
                                v-model="selectedShippingMethodKey"
                                color="primary"
                              >
                                <v-list-item :key="0">
                                  <v-list-item-icon>
                                    <v-icon v-text="'mdi-truck'"></v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title v-text="'Free Shipping'"></v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item :key="2">
                                  <v-list-item-icon>
                                    <v-icon v-text="'mdi-truck-fast'"></v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title v-text="'Priority Shipping'"></v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </template>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels> -->
            


                  <v-btn
                    class="prevBtn"
                    color="primary"
                    @click="prevStep"
                    :disabled="step === 1"
                    text
                    ><v-icon>mdi-arrow-left</v-icon>Return to Information</v-btn
                  >
                  <v-btn
                    class="nextBtn"
                    :block="$vuetify.breakpoint.xsOnly"
                    color="primary"
                    @click="nextStep"
                    :disabled="step === 4 || !customer.address || !customer.phone"  rounded x-large
                    >PROCEED TO PAYMENT</v-btn
                  >
                </v-form>
              </v-stepper-content>

              <v-stepper-step step="3"> Payment </v-stepper-step>
              <v-stepper-content step="3" style="    padding-left: 0px;
                  margin-left: 30px;" >
                <!-- Review & Confirm Section -->
                <div style="margin: 5px;" ref="card"></div>
                <v-btn
                  class="prevBtn"
                  color="primary"
                  @click="prevStep"
                  :disabled="step === 1"
                  text
                  ><v-icon>mdi-arrow-left</v-icon>Return to Shipping</v-btn
                >
                <div class="text-center">
                  <v-btn
                    class="nextBtn"
                    :block="$vuetify.breakpoint.xsOnly"
                    tonal
                    x-large
                    rounded
                    :disabled="!customer.paymentComplete || tax == 'calculated at payment'"
                    @click="checkout"
                    color="primary"
                    >Place Order</v-btn
                  >
                </div>
                <!-- Include a button to submit the order -->
              </v-stepper-content>
            </v-stepper>
          </v-col>
          <v-col class="col-md-4 col-sm-12 col-xs-12">
            <div :style="{'max-width': '700px', 'margin': 'auto', 'padding': $vuetify.breakpoint.xsOnly ? '30px': ''}">
              <p class="headline">Order Summary</p>
              <template v-for="(cartItem, i) of cartItems">
                <v-list-item
                  v-bind:key="cartItem"
                  :ripple="false"
                   :key="i"
                  style="padding:0px 0px 25px 0px;"
                >
                  <v-badge
                    stacked
                    left
                    offset-x="15px"
                    offset-y="15px"
                    color="#808080a3"
                    :value="false"
                  >
                    <v-img
                      class="orderItemImg"
                      :src="cartItem.metadataGroups.length == 1 ? cartItem.metadataGroups[0].metadataOptions.find(mi=>mi.id == cartItem.metadataGroups[0].defaultOptionId).img : cartItem.imgUrl"
                    ></v-img>
                  </v-badge>
                  <v-list-item-content
                    style="
                      padding: 0px 17px;
                      align-items: baseline;
                      flex-direction: column;
                      display: flex;
                    "
                  >
                    <h3 class="text-bold" style="margin-bottom: 7px; text-align: left">
                      {{ cartItem.name }}
                    </h3>

                    <p class="text-grey" style="text-align:left; font-weight: 200;">
                      <span
                        v-for="(meta, i) of cartItem.metadataGroups"
                        :key="meta.id"
                      >
                        {{
                          `${meta.metadataOptions.find(
                            (o) => o.id == meta.defaultOptionId
                          )?.title}${cartItem.metadataGroups.length == i + 1 ? "" : ","}`
                        }}
                      </span>
                    </p>
                    <p class="text-grey" style=" font-weight: 200;">{{ cartItem.qty }} x ${{
                        (
                          (cartItem.price -
                          cartItem.price *
                              parseFloat(cartItem.salePctOff) +
                            selectedMetadataOptionsTotal(cartItem))
                        ).toFixed(2)
                      }}</p>

                    <!-- <div style="right: 10px; position: absolute" class="text-bold">
                      ${{
                        (
                          (cartItem.price -
                          cartItem.price *
                              parseFloat(cartItem.salePctOff) +
                            selectedMetadataOptionsTotal(cartItem)) *
                            cartItem.qty
                        ).toFixed(2)
                      }}
                    </div> -->
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template>
                <v-form>
                  <v-text-field
                    dense
                    v-model="promoCode"
                    label="Enter Promo Code"
                    type="text"
                    required
                    :disabled="promoId"
                    ref="promoCodeField"
                    :rules="promoCodeRules"
                    class="mx-3"
                  >
                    <template #append>
                      <v-btn
                        style="border-radius: 0px !important"
                        color="primary"
                        :disabled="promoId"
                        @click="applyPromo"
                        >{{promoId ? 'Applied' : 'Apply'}}</v-btn
                      >
                    </template>
                  </v-text-field>
                </v-form>
                <!-- <span>This is a test message</span> -->
              </template>
              <br>
              <v-simple-table style="text-align: left">
                <template v-slot:default>
                  
                  <tbody>
                    <tr>
                      <td>Subtotal</td>
                      <td class="text-right" style="width: 50px">${{ promoId ? parseFloat(subtotal) + parseFloat(discountedAmount) : subtotal }}</td>
                    </tr>
                    <tr  v-if="promoId">
                      <td>Promo</td>
                      <td class="text-right" style="width: 50px">- ${{discountedAmount}}</td>
                    </tr>
                    <tr>
                      <td>Shipping</td>
                      <td class="text-right" style="width: 50px">{{ shipping == 0 ? 'FREE' : `$${shipping}` }} </td>
                    </tr>
                    <tr>
                      <td>Tax</td>
                      <td class="text-right" style="width: 50px">{{ tax == 'calculated at payment' ? '' : '$' }}{{ tax }}</td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td class="text-right" style="width: 50px">
                        <b>${{ total }}</b>
                      </td>
                    </tr>
                  </tbody>
                  <br>

                </template>
              </v-simple-table>
            </div>
          </v-col>
        </v-row>
      </v-card>

      <ActionDialog :actionFn="refreshSession" formTitle="Checkout Session Expired" description="test description" actionBtnText="Refresh Session" :dialog="expiredSessionDialog"></ActionDialog>


      <!-- Additional Information Section -->
    </v-container>
    <v-card class="accent">
      <v-container>
        <v-row no-gutters>
          <v-col class="col-12 col-md-4 col-sm-12">
            <v-row>
              <v-col class="col-12 col-sm-3 pr-4 hidden-sm-only" align="right">
                <v-icon class="display-2">mdi-truck</v-icon>
              </v-col>
              <v-col class="col-12 col-sm-9 pr-4">
                <h3 class="font-weight-light">FREE SHIPPING & RETURN</h3>
                <p class="font-weight-thin">Free Shipping over $300</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="col-12 col-md-4 col-sm-12">
            <v-row>
              <v-col class="col-12 col-sm-3 pr-4" align="right">
                <v-icon class="display-2">mdi-cash-usd</v-icon>
              </v-col>
              <v-col class="col-12 col-sm-9 pr-4">
                <h3 class="font-weight-light">MONEY BACK GUARANTEE</h3>
                <p class="font-weight-thin">30 Days Money Back Guarantee</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="col-12 col-md-4 col-sm-12">
            <v-row>
              <v-col class="col-12 col-sm-3 pr-4" align="right">
                <v-icon class="display-2">mdi-headset</v-icon>
              </v-col>
              <v-col class="col-12 col-sm-9 pr-4">
                <h3 class="font-weight-light">020-800-456-747</h3>
                <p class="font-weight-thin">24/7 Available Support</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import api from "@/api";
import debounce from "lodash/debounce";
import ActionDialog from "@/components/dialogs/actionDialog.vue";

let stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY),
  elements = stripe.elements(),
  card = undefined,
  linkAuth = undefined,
  addressElement = undefined,
  orderId = undefined;

export default {
  data: () => ({
    selectedShippingMethod: null,
    shippingMethodChangeLoading: false,
    open: 0,
    step: 1,
    intentResponse: undefined,
    promoCode: null,
    promoValid: true,
    promoId: null,
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        to: "/",
      },
      {
        text: "Shop",
        disabled: false,
        to: "shop",
      },
      {
        text: "Cart",
        disabled: false,
        to: "/cart",
      },
      {
        text: "Checkout",
        disabled: true,
        to: "/checkout",
      },
    ],
    discountedAmount:0,
    token: null,
    rating: 4.5,
    guestCheckout: false,
    tax: "calculated at payment",
    customer: {
      email: "",
      name: "",
      address: {},
      phone: "",
      paymentComplete: false
    },
    expiredSessionDialog: false
  }),
  computed: {
    ...mapState({
      appData: (state) => state.appData,
      user: (state) => state.user,
    }),
    ...mapGetters({
      numberOfCartItems: "getNumberOfCartItems",
      cartItems: "getCartItems",
    }),   
    promoCodeRules() {
      return [
        v => !!v || "Promo Code can't be empty",
        v => this.promoValid ? true : !!this.promoValid || 'Promo Code is invalid',
      ];
    },
    subtotal() {
      let total = 0;
      this.cartItems.forEach((cartItem) => {
        const itemTotal =
          ((cartItem.price - (cartItem.price *
              cartItem.salePctOff) )  + this.selectedMetadataOptionsTotal(cartItem)) *
          cartItem.qty;
        total += parseFloat(itemTotal.toFixed(2));
      });
      return (total - this.discountedAmount).toFixed(2);
    },
    shipping() {
      return this.selectedShippingMethod?.logisticPrice ?? 0.0;
    },
    total() {
      return (
        (parseFloat(this.subtotal) + parseFloat(this.shipping)) 
        + (this.tax != 'calculated at payment' ? parseFloat(this.tax) : 0)
      ).toFixed(2);
    },
  },
  methods: {
    ...mapMutations([
      "incrementCartItemQty",
      "decrementCartItemQty",
      "addCartItem",
      "removeCartItem",
    ]),
    async validatePromo(){
      if(!this.promoCode) return;
      const options = {promoCode: this.promoCode}
      if(this.user) options.userId = this.user.id
      const {data: promotion} = await api.post(`/api/promotion/check-validity`,options).catch(e=>{
        console.log(e)
        return this.promoValid = false;
      })
      this.$nextTick(() => {
        // Force re-render of the field
        this.$refs.promoCodeField.validate();
      });
      this.promoValid = promotion != null;
      this.promoId = promotion.id;
      this.discountedAmount = promotion.savings != "0.00" ? parseFloat(promotion.savings).toFixed(2) : (this.subtotal * parseFloat(promotion.salePctOff)).toFixed(2)
      return this.promoValid;
    },
    async applyPromo(){
      if(! (await this.validatePromo())) return;
      const metadata = {
        promoId: this.promoId,
        selectedShippingType: this.selectedShippingMethod.shippingType
      }
      this.updatePaymentIntent(
        {
          cartItems: [
            ...this.cartItems.map((cartItem) => {
              return {
                productId: cartItem.id,
                metadataGroups: cartItem.metadataGroups,
                quantity: cartItem.qty,
              };
            }),
          ],
          currency: 'CAD',
          metadata
        }).catch(e=>{
        console.log(e); 
      })
    },
    refreshSession(){
      this.step = 1;
      this.expiredSessionDialog = false;
    },
    async preparePaymentElements() {
      this.intentResponse = await this.generatePaymentIntent();
      let intent = this.intentResponse.stripe;
      orderId = this.intentResponse.orderId;

      this.selectedShippingMethod = this.intentResponse.shippingMethods.find(sm=>sm.title !="Priority Shipping")
   
      const appearance = {
        theme: this.$vuetify.theme.dark ? "night" : "flat",
        variables: { colorPrimaryText: "#262626" },
      };
      elements = stripe.elements({
        clientSecret: intent.client_secret,
        appearance,
      });
      const options = {
        layout: {
          type: "accordion",
          defaultCollapsed: false,
          radios: true,
          spacedAccordionItems: false,
        },
      };
      linkAuth = elements.create("linkAuthentication");
      linkAuth.mount(this.$refs.linkAuth);
      const debouncedLinkAuthEventHandler = debounce((event) => {
        if(this.customer.email == event.value.email) return;
        this.customer.email = event.value.email;
        if(this.customer.email == '' || event.complete == false) return;

        this.updateOrderEmail(this.customer.email)
      }, 300);
      linkAuth.on("change", debouncedLinkAuthEventHandler);

      card = elements.create("payment", options);
      card.mount(this.$refs.card);
      const debouncedCardEventHandler = debounce((event) => {
        this.customer.paymentComplete = event.complete
      }, 300);
      card.on("change", debouncedCardEventHandler);

      addressElement = elements.create("address", { mode: "shipping",
      allowedCountries: ['CA'],
      blockPoBox: true,
      fields: {
        phone: 'always',
      },
      validation: {
        phone: {
          required: 'always',
        },
  }, });
      addressElement.mount(this.$refs.addressElement);
      const debouncedAddressElementEventHandler = debounce((event) => {

        this.customer.address = event.value.address;
        this.customer.phone = event.value.phone;

      }, 300);
      addressElement.on("change", debouncedAddressElementEventHandler);
    },
    async generatePaymentIntent() {
      let options =
        {  
          cartItems: [
            ...this.cartItems.map((cartItem) => {
              return {
                productId: cartItem.id,
                metadataGroups: cartItem.metadataGroups,
                quantity: cartItem.qty,
              };
            }),
          ],
          currency: "CAD",
        }
      if(!this.guestCheckout){
        options.userId = this.user?.id
      }
      const paymentIntent = await api.post("/api/payment/intent", options);
      return paymentIntent.data;
    },
    async updatePaymentIntent(options) {
      const paymentIntent = await api.put(`/api/payment/intent/${this.intentResponse.stripe.id}`, options);
      return paymentIntent.data;
    },
    async checkout() {
      const order = await api.get(`/api/payment/ensure-payment/${orderId}`).catch(e=>{
        this.expiredSessionDialog = true;
      })
      if(!order) { this.expiredSessionDialog = true; return;} //notify user session expired, button to refresh
      stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/order-successful?id=${orderId}`,
          receipt_email: this.customer.email,
        },
      });
    },
    updateOrderEmail(email){
      return api.put(`/api/order/${orderId}/email`,{email})
      .catch(e=>console.log("error occured while updating order email"))
    },
    selectedMetadataOptionsTotal(cartItem) {
      let total = 0;
      cartItem?.metadataGroups?.forEach((meta) => {
        total += parseFloat(
          meta.metadataOptions.find(
            (option) => option.id == meta.defaultOptionId
          )?.price ?? 0
        );
      });
      return parseFloat(total.toFixed(2));
    },
    changeQty(newQty, cartItemId) {
      let cartItem = this.cartItems.find((ci) => ci.id == cartItemId);
      if (newQty == 0) {
        return this.removeCartItem(cartItemId);
      }
      if (newQty > cartItem.qty) {
        while (cartItem.qty != newQty) {
          this.incrementCartItemQty(cartItemId);
        }
      } else if (newQty < cartItem.qty) {
        while (cartItem.qty != newQty) {
          this.decrementCartItemQty(cartItemId);
        }
      }
    },
    nextStep() {
      this.step++;
    },
    prevStep() {
      this.step--;
    },
    validateStep1() {
      // Add validation logic for step 1 (shipping information)
      // Return true if validation passes, false otherwise
      return true;
    },
    validateStep2() {
      // Add validation logic for step 2 (payment information)
      // Return true if validation passes, false otherwise
      return true;
    },
    async handleShippingMethodChange(shippingMethod){
      if(!shippingMethod || !this.intentResponse) return;
      this.shippingMethodChangeLoading = true;
      const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
      delay(1300).then(()=>this.shippingMethodChangeLoading=false);
      this.updatePaymentIntent({
        currency: 'CAD',
        metadata: {
          promoId: this.promoId,
          selectedShippingType: shippingMethod.shippingType,
          orderId: orderId,
        },
        cartItems: [
          ...this.cartItems.map((cartItem) => {
            return {
              productId: cartItem.id,
              metadataGroups: cartItem.metadataGroups,
              quantity: cartItem.qty,
            };
          }),
        ],
      })
    },
  },
  async mounted(){
    this.preparePaymentElements();
    if(!this.user?.id){
      this.guestCheckout = true;
    }
  },
  watch: {
    step(val) {
      if (val == 1) {
        //payment
        this.preparePaymentElements();
      }
      if(val == 3){
        this.updatePaymentIntent({
          currency: 'CAD',
          metadata: {
            promoId: this.promoId,
            selectedShippingType: this.selectedShippingMethod.shippingType,
            orderId: orderId,
            address: JSON.stringify({
              city: this.customer.address.city,
              postal_code: this.customer.address.postal_code,
              country: this.customer.address.country,
              state: this.customer.address.state
            })
          },
          cartItems: [
            ...this.cartItems.map((cartItem) => {
              return {
                productId: cartItem.id,
                metadataGroups: cartItem.metadataGroups,
                quantity: cartItem.qty,
              };
            }),
          ],
        }).then(({metadata})=>{
          this.tax = parseFloat(metadata.tax);
        })
      }
    },
  },
  components: {
    ActionDialog,
  }
};
</script>

<style scoped lang='scss'>
  #checkoutView{
    @media screen and (max-width: 600px){
      .orderItemImg{
        height:80px!important;
        width:80px!important;
      }
      .nextBtn, .prevBtn {
        float: unset!important;
        margin: 10px 0px!important;
      }
      .rounded-card{
        margin:0px!important;
      }
    }
    .prevBtn {
      float: left;
      margin: 10px;
    }
    .nextBtn {
      float: right;
      margin: 10px;
    }
    .orderItemImg{
      width: 100px; 
      height: 100px; 
      border-radius: 20%;
    }
  }
  
</style>