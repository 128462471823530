<template>
    <v-container style="max-width: 1620px; min-height: 80vh;display: grid;">
     
    <div>
        <v-snackbar
            style="margin-bottom: 55px"
            v-model="snackbar"
            text
            outlined
            :color="saveResponse?.status == 200 || saveResponse?.status == 201? 'success' : 'error'"
            centered
            >
            {{
                (saveResponse?.status == 200 || saveResponse?.status == 201)
                ? "App Data was successfully saved"
                : "Something went wrong"
            }}
            <template v-slot:action="{ attrs }">
                <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                Close
                </v-btn>
            </template>
        </v-snackbar>

        <br />

        <v-container fluid>
            <v-row>
                <Collapsable title="Users" :search="(val)=>(this.$refs.usersTable.search = val)">
                    <Datatable ref="usersTable" :data="users" :bulkSelect="true" :headers="usersHeaders">

                        <template v-slot:bulkActions="slotProps">
                            <v-btn @click="()=>slotProps.selectedItems.forEach(item=>item)">Bulk Delete</v-btn>
                        </template>

                        <template v-slot:extraAction="slotProps"> 
                            <v-icon title="deactivate" v-if="slotProps.editedItem.isActive" @click="deactivateUser(slotProps.editedItem)">mdi-account-cancel</v-icon>
                            <v-icon title="activate" v-else @click="activateUser(slotProps.editedItem)">mdi-account-check</v-icon>
                        </template>
                    </Datatable>
                </Collapsable>
            </v-row>
        </v-container>
    </div>

</v-container>
</template>

<script>
import api from "@/api";
import Collapsable from "@/components/collapsable.vue";
import Datatable from "@/components/datatable.vue";

import PromosDialog from "@/components/dialogs/promosDialog.vue";
export default {
    data(){
        return {
            snackbar: false,
            saveResponse: null,
            usersHeaders: [
                {
                text: 'User ID',
                align: 'start',
                value: 'id',
                },
                { text: 'Email', value: 'email'},
                { text: 'First Name', value: 'firstName'},
                { text: 'Last Name', value: 'lastName'},
                { text: 'Active Status', value: 'isActive'},
                { text: 'Verified Status', value: 'isVerified'},
                { text: 'Last Login', value: 'updatedAt'},
                { text: 'Actions', value: 'actions'},
            ],
            users: [],
        }
    },
    methods:{

        reviver(key, value) {
            // Check if the value is a string that represents a number
            if (/^-?\d+(\.\d+)?$/.test(value)) {
                // Check if the value is a decimal number or a whole number
                if (/^-?\d+\.\d+$/.test(value)) {
                    // Convert the string value to a decimal number
                    return parseFloat(value);
                } else {
                    // Convert the string value to an integer
                    return parseInt(value);
                }
            }
            return value;
        },
        fetchUsers(){
            return api.get('/api/user').then(resp=>{
                this.users = resp.data
                return resp;
            }).catch(e=>{console.log(e); return e;})
        },
        async deactivateUser(user){
            this.saveResponse = await api.get(`/api/user/deactivate/${user.id}`)
            .catch(e=>{console.log(e); return e;})
            this.snackbar = true;
            user.isActive = false;
        },
        async activateUser(user){
            this.saveResponse = await api.get(`/api/user/activate/${user.id}`)
            .catch(e=>{console.log(e); return e;})
            this.snackbar = true;
            user.isActive = true;
        },
        // async updatePromo(editedItem){
        //     return api.put(`/api/promotion/${editedItem.id}`,JSON.parse(JSON.stringify(editedItem), this.reviver))
        //     .catch(e=>{console.log(e); return e.error;})
        // },
    },
    async beforeMount(){
        this.saveResponse = await this.fetchUsers();
        console.log("users", this.users)

    },
    components: {
        // ShopView,
        Collapsable,
        Datatable,
        PromosDialog,
    }
}
</script>

<style>
.truncated {
  max-width: 200px; /* Set the maximum width for the cell */
  white-space: nowrap; /* Prevent line breaks */
  overflow: hidden; /* Hide any overflowing text */
  text-overflow: ellipsis; /* Add an ellipsis (...) to indicate truncated text */
}
</style>