<template>
  <v-data-table :show-expand="fetchExpandedData" @item-expanded="fetchExpandedData" single-expand
  :search="search" :show-select="bulkSelect" :headers="headers" :items="data" v-model="selectedItems">
    <template v-slot:top>
      <slot v-if="selectedItems.length>0" name="bulkActions" :data="data" :editedItem="editedItem" :selectedItems="selectedItems"></slot>
      <v-spacer></v-spacer>
        <slot name="default" :editedIndex="editedIndex" :save="save" :close="close" :dialog="dialog" :editedItem="editedItem"></slot>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template v-slot:item.description="{ item }">
      <div class="truncate">{{ truncate( item.description ,50, '...') }}</div>
    </template>


    <template v-slot:expanded-item="{ headers, item }">
      <slot name="expanded" :headers="headers" :item="item"></slot>
    </template>

    <template v-slot:item.actions="{ item }">
        <v-icon v-if="saveData" small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon v-if="deleteData" small class="mr-2" @click="deleteItem(item)"> mdi-delete </v-icon>
        <slot name="extraAction" :editedItem="item" :data="data"></slot>

    </template>
  </v-data-table>
</template>

<script>

export default {
  props: ["saveData", "deleteData", "data", "headers", "bulkSelect", "fetchExpandedData"],
  data() {
    return {
      selectedItems: [],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      search:'',
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      console.log("item",item)
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.data.splice(this.editedIndex, 1);
      this.closeDelete();
      console.log("editedItem inside deleteItemConfirm", this.editedItem)
      this.deleteData(this.editedItem);
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    addItem() {
      if (this.editedIndex > -1) {
        Object.assign(this.data[this.editedIndex], this.editedItem);
      } else {
        this.data.push(this.editedItem);
      }
      this.close();
    },

    async save() {
      if (this.editedIndex > -1) {
        Object.assign(this.data[this.editedIndex], this.editedItem);
      } else {
        this.data.push(this.editedItem);
      }
      this.close();
      this.saveData(this.editedItem);
    },
  },
  beforeMount(){
    let hs = [...this.headers]
    for (const header of hs) {
        this.editedItem[header.value] = '';
    }
    delete this.editedItem.actions
    this.defaultItem = this.editedItem;
  },
  computed:{
    truncate(){
      return (text, length, clamp)=>{
        clamp = clamp || '...';
        var node = document.createElement('div');
        node.innerHTML = text;
        var content = node.textContent;
        return content.length > length ? content.slice(0, length) + clamp : content;
      }
    }
  }
};
</script>
