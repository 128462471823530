<template>
  <div id='orderFailedView'>
    <v-container style="max-width: 1620px">
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <v-card
        class="my-10 rounded-card"
        style="width: 100%; margin: auto; max-width:1370px;"
      >
        <v-row>
          <v-col>
            <div style="width: 100%; text-align:left;" class="pa-4">
              <p class="display-2 mb-6">
                PAYMENT FAILED. OOPS!
                <img class="check" src="@/assets/img/cancel.png">
              </p>
              <p class="mb-10">We've got some bad news. Your order has been set to failed status. We were unable to process your online payment at this time.</p>
              
              <br>
              <v-btn color="primary" to="checkout"
                  x-large rounded>TRY AGAIN</v-btn>
            </div>

            <br />
          </v-col>
          <v-col>
            <OrderSummary :order="order"></OrderSummary>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-card class="accent">
      <v-container>
        <v-row no-gutters>
          <v-col class="col-12 col-md-4 col-sm-12">
            <v-row>
              <v-col class="col-12 col-sm-3 pr-4 hidden-sm-only" align="right">
                <v-icon class="display-2">mdi-truck</v-icon>
              </v-col>
              <v-col class="col-12 col-sm-9 pr-4">
                <h3 class="font-weight-light">FREE SHIPPING & RETURN</h3>
                <p class="font-weight-thin">Free Shipping over $300</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="col-12 col-md-4 col-sm-12">
            <v-row>
              <v-col class="col-12 col-sm-3 pr-4" align="right">
                <v-icon class="display-2">mdi-cash-usd</v-icon>
              </v-col>
              <v-col class="col-12 col-sm-9 pr-4">
                <h3 class="font-weight-light">MONEY BACK GUARANTEE</h3>
                <p class="font-weight-thin">30 Days Money Back Guarantee</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="col-12 col-md-4 col-sm-12">
            <v-row>
              <v-col class="col-12 col-sm-3 pr-4" align="right">
                <v-icon class="display-2">mdi-headset</v-icon>
              </v-col>
              <v-col class="col-12 col-sm-9 pr-4">
                <h3 class="font-weight-light">020-800-456-747</h3>
                <p class="font-weight-thin">24/7 Available Support</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<style lang="scss" scoped>
  #orderFailedView {
    @media screen and (max-width: 500px){
      .rounded-card{
        padding: 10px 4px 30px 4px !important;
      }
      .check{
        margin:auto;
        display:block;
      }
    }
    .rounded-card{
      padding: 40px;
    }
  }
  
</style>
  <script>
import api from "@/api";
import { mapState, mapMutations } from "vuex";
import OrderSummary from "../components/OrderSummary.vue";

export default {
  data() {
    return {
      loading: false,
      emailCode: null,
      order: null,
      codeRules: [
        (v) => !!v || "code is required",
        (v) => /[0-9]{5}/.test(v) || "Code must be a 5 digit whole number",
      ],
      verifyResponse: "",
      sendCodeResponse: "",
      invalid: false,
      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          to: "/",
        },
        {
          text: "Shop",
          disabled: false,
          to: "shop",
        },
        {
          text: "Order",
          disabled: true,
          to: "order-successful",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      appData: (state) => state.appData,
      isLoggedIn: (state) => state.isLoggedIn,
    }),
  },
  methods: {
    async fetchOrder() {
      const orderId = this.$route.query.id;
      console.log("orderid", orderId);
      api
        .get(`/api/order/${orderId}`)
        .then((res) => {
          this.order = res.data;
        })
        .catch((e) => console.log(e));
      console.log("order", this.order);
    },
    async fetchPaymentIntentStatus(){
      let status = await api.get(`/api/payment/intent-status/${this.$route.query.payment_intent}`).catch(e=>console.log(e))
      return status
    }
  },
  async beforeMount() {
    this.fetchOrder();
    let status = (await this.fetchPaymentIntentStatus()).data;
    console.log(status)
    if(status == 'requires_payment_method'){
      // this.$router.push('/order-failed')
    }
  },
  components: {
    OrderSummary,
  },
};
</script>