<template>
  <div :class="{'mx-3':true, 'dark-mode': $vuetify.theme.dark}" style="position: relative">
    <div class="receipt-slot"></div>
    <div style="max-width: 700px; margin: auto" class="receipt">
      <p
        class="divider text-bold"
        style="
          font-weight: bold;
          text-align: left;
          padding-bottom: 20px;
          font-size: 30px;
        "
      >
        Order Summary
      </p>
      <v-row no-gutters style="text-align: left">
        <v-col class="receipt-col">
          <span class="text-grey"
            >Date<br />
            <div class="text-bold">
              {{ formatDate(order?.orderDate) }}
            </div>
          </span>
        </v-col>
        <v-col class="receipt-col">
          <span class="text-grey"
            >OrderNumber<br />
            <div class="text-bold">{{ order?.orderNumber }}</div></span
          >
        </v-col>
        <v-col class="receipt-col">
          <span class="text-grey"
            >IntentId<br />
            <div class="text-bold">{{ order?.intent }}</div></span
          >
        </v-col>
      </v-row>
      <br />
      <div class="dashed-lines">
        <div class="circle1"></div>
        <div class="circle2"></div>
      </div>

      <div class="divider ma-5">
        <v-list
          v-if="order?.orderItems.length > 0"
          style="max-height: 600px; background-color: #f5f5f5"
        >
          <template v-for="orderItem in order.orderItems">
            <v-list-item
              v-bind:key="orderItem.id"
              :ripple="false"
              style="padding: 0px 0px 25px 0px"
            >
              <!-- <v-img
                  style="width: 100px; height: 100px; border-radius:20%;"
                  :src="orderItem.product.imgUrl"
                ></v-img> -->
              <v-badge
                stacked
                left
                offset-x="15px"
                offset-y="15px"
                color="#808080a3"
                :value="false"
              >
                <v-img
                  style="width: 100px; height: 100px; border-radius: 20%"
                  :src="orderItem.product.imgUrl"
                ></v-img>
              </v-badge>
              <v-list-item-content
                style="
                  padding: 0px 17px;
                  align-items: baseline;
                  flex-direction: column;
                  display: flex;
                "
              >
                <h3 class="text-bold" style="margin-bottom: 7px; text-align: left">
                  {{ orderItem.product.name }}
                </h3>

                <p class="text-grey" style="text-align:left; font-weight: 200;">
                  <template v-for="(option, i) of orderItem.metadataOptions">
                    {{
                      (option.title ?? "") +
                      (orderItem.metadataOptions.length != i + 1 ? "," : "")
                    }}
                  </template>
                </p>
                <p class="text-grey" style=" font-weight: 200;">Qty: {{ orderItem.quantity }}</p>

                <div style="right: 10px; position: absolute" class="text-bold">
                  ${{
                    (
                      (orderItem.price -
                        orderItem.price *
                          parseFloat(orderItem.product.salePctOff) +
                        selectedMetadataOptionsTotal(orderItem)) *
                      orderItem.quantity
                    ).toFixed(2)
                  }}
                </div>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </div>

      <div class="ma-5">
        <div class="divider mb-5 pb-5 text-grey">
          <div style="text-align:left;">Subtotal <div style="float:right; font-weight: 200;">${{ subtotal }}</div></div>
          <div v-if="discountedAmount > 0" style="text-align:left;">Promo <div style="float:right; font-weight: 200;">- ${{ discountedAmount }}</div></div>
          <div style="text-align:left;">Shipping <div style="float:right; font-weight: 200;">${{ shipping }}</div></div>
          <div style="text-align:left;">Tax <div style="float:right; font-weight: 200;">${{ tax }}</div></div>
        </div>
        <div class="text-bold" style="text-align:left;">Order Total <div style="float:right;">${{ total }}</div></div>

      </div>

    </div>

  </div>
</template>
  <style lang="scss" scoped>

  @media screen and (max-width: 500px){
    .receipt{
      padding: 10px !important;
    }
    .receipt-col{
      &:nth-child(2) {
        border-right: none!important;
      }
    }
    .dashed-lines{
      width: calc(100% + 30px) !important;
      margin-left: -15px !important;
    }
  }
.dark-mode {
  .receipt {
    background-image: url('data:image/svg+xml;utf8, <svg viewBox="0 0 200 110" xmlns="http://www.w3.org/2000/svg"><path d="M -15 110 L100 10 L215 110" fill="%231e1e1e" stroke="%23f5f5f5" stroke-width="4" vector-effect="non-scaling-stroke"/></svg>');
  }
  .circle1, .circle2 {
    background-color: #1e1e1e;
  }
  .receipt-slot {
    background-color: black;
  }
}
.receipt {
  border: 4px #f5f5f5 solid;
  background-color: #f5f5f5;
  border-bottom: 0;
  background-image: url('data:image/svg+xml;utf8, <svg viewBox="0 0 200 110" xmlns="http://www.w3.org/2000/svg"><path d="M -15 110 L100 10 L215 110" fill="white" stroke="%23f5f5f5" stroke-width="4" vector-effect="non-scaling-stroke"/></svg>');
  background-position: bottom left;
  background-size: 10% auto;
  background-repeat: repeat-x;
  padding: 30px;
}
.receipt-slot {
  border-radius: 50px;
  width: calc(100% + 20px);
  margin: 0px 0px -13px -10px;
  height: 25px;
  background-color: #ededed;
}
.dashed-lines {
  position: relative;
  width: calc(100% + 70px);
  margin-left: -35px;
  border-bottom: 2px dashed #e0e0e0;
  margin-bottom: 15px;
}
.circle1 {
  background-color: white;
  width: 30px;
  height: 30px;
  position: absolute;
  top: -15px;
  right: -15px;
  border-radius: 50%;
}
.circle2 {
  background-color: white;
  width: 30px;
  height: 30px;
  position: absolute;
  top: -15px;
  left: -15px;
  border-radius: 50%;
}
.text-grey {
  color: #979ca8;
  font-weight: bold;
}
.text-bold {
  color: #0b0b0b;
  font-weight: bold;
}
.divider {
  border-bottom: 1px solid #e0e0e0;
}
.receipt-col{
  &:not(:last-child) {
    border-right: 1px solid #e0e0e0;
  }
  margin-right: 15px;
  padding-right: 15px !important;
}
</style>
  <script>
import api from "@/api";
import { mapState, mapMutations } from "vuex";

export default {
  props:['order', 'metadata'],
  data() {
    return {
      loading: false,
      emailCode: null,
      codeRules: [
        (v) => !!v || "code is required",
        (v) => /[0-9]{5}/.test(v) || "Code must be a 5 digit whole number",
      ],
      verifyResponse: "",
      sendCodeResponse: "",
      invalid: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      appData: (state) => state.appData,
      isLoggedIn: (state) => state.isLoggedIn,
    }),
    subtotal() {
     return this.metadata.subtotal;
    },
    shipping() {
      return this.metadata.shipping;
    },
    tax() {
      return this.metadata.tax;
    },
    discountedAmount(){
      return this.metadata.discountedAmount;
    },
    total() {
      return (
        (parseFloat(this.subtotal) + parseFloat(this.shipping) - parseFloat(this.discountedAmount)) *
        1.13
      ).toFixed(2);
    },
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "short" }); // Get short month name
      const year = date.getFullYear();
      return `${day} ${month} ${year}`;
    },
    selectedMetadataOptionsTotal(orderItem) {
      let total = 0;
      orderItem?.metadataOptions?.forEach((option) => {
        total += parseFloat(option.price ?? 0)
      });
      return parseFloat(total.toFixed(2));
    },
  }
};
</script>