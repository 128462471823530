<template>
    <v-dialog v-model="dialog" max-width="80%">
    <v-card>
      <v-card-title>
        <span class="text-h5">Send Newsletter Mail</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="email.subject"
                label="Subject"
                @input="rerender"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <TemplateSelector :default="email.template" title="Email Template" @Input="(v)=>{email.template = v.template; email.metadata = JSON.stringify(v.metadata, null, 4); rerender();}"></TemplateSelector>
            </v-col>
          </v-row>

          <v-row>
            <v-col style="text-align: left;">
              <codemirror v-model="email.metadata" :options="cmOptions"></codemirror>
            </v-col>
          </v-row>

        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
        <v-btn color="primary" @click="save"> Send </v-btn>
      </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
import Collapsable from '../collapsable.vue';
import TemplateSelector from '../selectors/templateSelector.vue';

import 'codemirror/mode/css/css.js'
import 'codemirror/mode/xml/xml.js'
import 'codemirror/mode/htmlmixed/htmlmixed.js'
import 'codemirror/addon/display/autorefresh';

// require active-line.js
import'codemirror/addon/selection/active-line.js'
// autoCloseTags
import'codemirror/addon/edit/closetag.js'
  // language
  import 'codemirror/mode/javascript/javascript.js'

  // theme css
  import 'codemirror/theme/monokai.css'

  // require active-line.js
  import'codemirror/addon/selection/active-line.js'

  // styleSelectedText
  import'codemirror/addon/selection/mark-selection.js'
  import'codemirror/addon/search/searchcursor.js'

  // hint
  import'codemirror/addon/hint/show-hint.js'
  import'codemirror/addon/hint/show-hint.css'
  import'codemirror/addon/hint/javascript-hint.js'
  import'codemirror/addon/selection/active-line.js'

  // highlightSelectionMatches
  import'codemirror/addon/scroll/annotatescrollbar.js'
  import'codemirror/addon/search/matchesonscrollbar.js'
  import'codemirror/addon/search/searchcursor.js'
  import'codemirror/addon/search/match-highlighter.js'

  // keyMap
  import'codemirror/mode/clike/clike.js'
  import'codemirror/addon/edit/matchbrackets.js'
  import'codemirror/addon/comment/comment.js'
  import'codemirror/addon/dialog/dialog.js'
  import'codemirror/addon/dialog/dialog.css'
  import'codemirror/addon/search/searchcursor.js'
  import'codemirror/addon/search/search.js'
  import'codemirror/keymap/sublime.js'

  // foldGutter
  import'codemirror/addon/fold/foldgutter.css'
  import'codemirror/addon/fold/brace-fold.js'
  import'codemirror/addon/fold/comment-fold.js'
  import'codemirror/addon/fold/foldcode.js'
  import'codemirror/addon/fold/foldgutter.js'
  import'codemirror/addon/fold/indent-fold.js'
  import'codemirror/addon/fold/markdown-fold.js'
  import'codemirror/addon/fold/xml-fold.js'

export default {
    props: {
        save: {
            type: Function,
            required: true
        },
        close: {
            type: Function,
            required: true
        },
        dialog: {
            type: Boolean,
            required: true
        },
        email: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            savingsOverride: false,
            defaultItem: {},
            percentMask: "##0.00",
            mask: "###0.00",
            percentRule: v => {
              const value = Number(v);
              return (isNaN(value) || value < 0 || value > 100 || !/^\d+(\.\d{1,2})?$/.test(v)) ? "Invalid percent value" : true;
            },
            numberRule: v => {
              const value = Number(v);
              return (isNaN(value) || value < 0 || value > 300 || !/^\d+(\.\d{1,2})?$/.test(v)) ? "Invalid price value, must be 0$ - $300" : true;
            },
            cmOptions: {
              tabSize: 4,
              styleActiveLine: false,
              styleSelectedText: false,
              line: true,
              foldGutter: true,
              gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
              highlightSelectionMatches: { showToken: /\w/, annotateScrollbar: true },
              mode: 'text/javascript',
              hintOptions:{
                completeSingle: false
              },
              keyMap: "sublime",
              matchBrackets: true,
              showCursorWhenSelecting: true,
              theme: "monokai",
              extraKeys: { "Ctrl": "autocomplete" }
            }
        };
    },
    watch: {
        dialog(state) {
            if (!state) {
                this.close();
            }else{
              this.email.metadata = JSON.stringify(this.email.metadata, null, 4)
            }
            console.log("metadata", this.email.metadata)
        }
    },
    methods:{
      rerender() {
        this.$forceUpdate();
      },
    },
    computed: {

    },
    components: { Collapsable, TemplateSelector}
}
</script>