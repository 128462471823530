<template>
    <v-container style="max-width: 1620px; min-height: 80vh;display: grid;">
      <v-card class="pa-10 my-10" style="width: 100%; max-width:600px; margin:auto;">
        <div style="width: 100%; text-align: left">
        </div>
        <div class="display-1">REQUEST PASSWORD RESET</div><br>
        <div class="subtitle">We will send you an email to reset your password</div> <br>
      <v-form validation  v-model="invalid" ref="form" @submit.prevent="userVerify">
        <v-text-field  prepend-inner-icon="email" :rules="emailRules" v-model="email" label="Email" type="email" required></v-text-field>

        <v-btn :disabled="!invalid || !triedOnce" class="ma-2" outlined color="primary" :loading="loading" @click="resendEmail">Resend Email</v-btn>
        <v-btn :disabled="!invalid || triedOnce" type="submit" color="primary" @click="sendEmail">SEND EMAIL</v-btn>
      </v-form>
    <br>
      <v-alert v-if="verifyResponse && verifyResponse?.status == 200" type="success">Password Reset Email Sent Successful!</v-alert>
      <v-alert  v-if="verifyResponse && verifyResponse?.status != 200" type="error">
        <ul>
          <template v-if="Array.isArray(verifyResponse.data.error)">
            <ul>
              <li v-for="(error, i) in verifyResponse.data.error" :key="i">{{ error }}</li>
            </ul>
          </template>
          <template v-else>
          {{ verifyResponse.data.error }}
          </template>
        </ul>
      </v-alert>
      </v-card>
    </v-container>
</template>
  
  <script>
  import api from '@/api'
  import { mapState,mapMutations } from 'vuex';
  
  export default {
    data() {
      return {
        loading: false,
        email: null,
        triedOnce: false,
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        verifyResponse: '',
        sendCodeResponse: '',
        invalid: false,
      };
    },
    computed: {
      ...mapState({
        user: state => state.user,
        appData: state => state.appData,
        isLoggedIn: state => state.isLoggedIn,
      }),
    },
    methods: {
      async resendEmail(){
        try {
          this.verifyResponse = await api.post('/api/auth/forgot-password', { email:this.email });
          this.sendCodeResponse = null;
        } catch (error) {
          this.verifyResponse = error.response
          console.error(error);
        }
      },
      async sendEmail() {
        this.triedOnce = true;
        try {
          this.verifyResponse = await api.post('/api/auth/forgot-password', { email:this.email });
          this.sendCodeResponse = null;
        } catch (error) {
          this.verifyResponse = error.response
          console.error(error);
        }
      },
    },
  };
  </script>