<template>
    <v-container style="max-width: 1620px; min-height: 80vh;display: grid;">
      <v-card class="pa-10 my-10" style="width: 100%; max-width:600px; margin:auto;">
        <div style="width: 100%; text-align: left">
        </div>
        <div class="display-1">RESET YOUR PASSWORD </div><br>
        <div class="subtitle">Enter your new password below:</div> <br>
      <v-form validation  v-model="invalid" ref="form" @submit.prevent="userVerify">

        <v-text-field 
        :append-icon="pwdVisibility ? 'visibility' : 'visibility_off'"
        @click:append="() => (pwdVisibility = !pwdVisibility)"
        :type="pwdVisibility ? 'text' : 'password'" 
        :rules="passwordRules" 
        v-model="password" 
        label="Password" 
        required>
      </v-text-field>
      <v-text-field :rules="confirmPasswordRules" v-model="confirmPassword" label="Confirm Password" type="password" required></v-text-field>
  

        <v-btn :disabled="!invalid" type="submit" color="primary" @click="updatePassword">SUBMIT</v-btn>
      </v-form>
    <br>
      <v-alert v-if="updateResponse && updateResponse?.status == 200" type="success">Your Password has been Successfully Reset!<br>
         Please try to login <router-link to="/login">here</router-link></v-alert>
      <v-alert  v-if="updateResponse && updateResponse?.status == 401" type="error"> Password Link Expired</v-alert>
      <v-alert  v-if="updateResponse && updateResponse?.status != 200 && updateResponse?.status != 401" type="error">
        <ul>
          <template v-if="Array.isArray(updateResponse.data.error)">
            <ul>
              <li v-for="(error, i) in updateResponse.data.error" :key="i">{{ error }}</li>
            </ul>
          </template>
          <template v-else>
          {{ updateResponse.data.error }}
          </template>
        </ul>
      </v-alert>
      </v-card>
    </v-container>
</template>
  
  <script>
  import api from '@/api'
  import { mapState,mapMutations } from 'vuex';
  
  export default {
    data() {
      return {
        loading: false,
        email: null,
        password: '',
        passwordRules: [
            v => /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).+$/.test(v) || 'Password must contain at least 1 special character, number, and capitalized character, and 8 chars in length or more',
        ],
        confirmPasswordRules: [
          v => !!v || 'Confirm Password is required',
          v => v === this.password || 'Passwords do not match'
        ],
        updateResponse: '',
        invalid: false,
        pwdVisibility: false
      };
    },
    computed: {
      ...mapState({
        user: state => state.user,
        appData: state => state.appData,
        isLoggedIn: state => state.isLoggedIn,
      }),
    },
    methods: {
      async updatePassword() {
        try {
          this.updateResponse = await api.post('/api/auth/reset-password', { otp: this.$route.query.otp, password: this.password });
          if(this.updateResponse?.status == 200) //maybe do something we'll see
          this.sendCodeResponse = null;
        } catch (error) {
          this.updateResponse = error.response
          console.error(error);
        }
      },
    },
  };
  </script>