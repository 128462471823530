<template>
  <v-select
      :label="title"
      persistent-hint
      outlined
      :items="items"
      :item-value="(s)=>s.value"
      item-text="name"
      v-model="selectedItem"
      @change="setSelectedValue"
  ></v-select>
</template>

<script>
import api from '@/api'

export default {
props:["title","default"],
data(){
  return {
      items: [], 
      selectedItem: null, 
  }
},
methods:{
  setSelectedValue(value) {
      this.selectedValue = value;
      this.$emit('Input', value);
  },
  async fetchTemplates(){
      return await api.get(`/api/mail/mail-templates`)
  }
},
async beforeMount(){
  this.items = (await this.fetchTemplates()).data.map(t=>({name:t.template, value:t}))
  this.selectedItem = this.default;
},
watch: {
    default(newValue) {
        this.selectedItem = newValue;
    },
},
}
</script>