<template>
    <v-select
        :label="title"
        persistent-hint
        outlined
        :items="items"
        :item-value="(s)=>s.value"
        item-text="email"
        v-model="selectedItem"
        @change="setSelectedValue"
    ></v-select>
  </template>
  
  <script>
  import api from '@/api'
  
  export default {
  props:["title","default"],
  data(){
    return {
        items: [], 
        selectedItem: null, 
    }
  },
  methods:{
    setSelectedValue(value) {
        this.selectedValue = value;
        this.$emit('Input', value);
    },
    async fetchUsers(){
        return await api.get(`/api/user`)
    }
  },
  async beforeMount(){
    this.items = (await this.fetchUsers()).data.map(c=>({email:c.email, value:c.id}))
    this.selectedItem = this.default;
  },
  watch: {
      default(newValue) {
        console.log("default", newValue)
          this.selectedItem = newValue;
      },
  },
}
</script>