<template>
    <v-container style="max-width: 1620px; min-height: 80vh;display: grid;">
     <v-card class="pa-10 my-10" style="width: 100%; max-width:700px; margin:auto;">
           <div style="width: 100%; text-align: center">
          <!-- <v-img
            style="height: 100px; width:fit-content; margin-left: auto; margin-right: auto"
            :src="!darkMode
              ? this.appData.assetUrls.logo
              : this.appData.assetUrls.logoDark"
          /> -->
          <p class="display-1 font-weight-light	text-center pa-4">ACCOUNT</p>
      </div>
    <v-form validation  v-model="invalid" ref="form" @submit.prevent="updateUser">
      <v-text-field :rules="nameRules" v-model="fName" label="First Name" type="text" required></v-text-field>

      <v-text-field :rules="nameRules" v-model="lName" label="Last Name" type="text" required></v-text-field>

      <!-- <v-text-field :rules="emailRules" v-model="email" label="Email" type="email" required></v-text-field> -->
    
      <v-btn :disabled="!invalid || !canEdit" type="submit" color="primary">Save Details</v-btn>
    </v-form>
    <br>
    <v-alert v-if="updateResponse && (updateResponse?.status == 200)" type="success">User Details Saved Successfully</v-alert>
    <v-alert  v-if="updateResponse && updateResponse?.status != 200" type="error">
      <ul>
        <template v-if="Array.isArray(updateResponse.data.error)">
          <li v-for="(error, i) in updateResponse.data.error" :key="i">{{ error }}</li>
        </template>
        <template v-else>
          <li>{{ updateResponse.data.error }}</li>
        </template>
      </ul>
    </v-alert>
     </v-card>
    </v-container>
</template>
  
  <script>
  import { mapMutations, mapState } from 'vuex';
  import api from '@/api'
  
  export default {
    data() {
      return {
        fName: '',
        lName: '',
        email: '',
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        nameRules: [
            v => !!v || 'Name fields are required',
        ],
        updateResponse: '',
        invalid: false,
        passwordRules: [
            v => /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).+$/.test(v) || 'Password must contain at least 1 special character, number, and capitalized character, and 8 chars in length or more',
        ],
        confirmPasswordRules: [
          v => !!v || 'Confirm Password is required',
          v => v === this.password || 'Passwords do not match'
        ],
        pwdVisibility: false
      };
    },
    mounted(){
      this.fName = this.user.firstName;
      this.lName = this.user.lastName
      this.email = this.user.email;
    },
    methods: {
      async updateUser() {
        try {
          this.updateResponse = await api.put(`/api/user/${this.user.id}`, { email: this.email, firstName: this.fName, lastName: this.lName});
          if(this.updateResponse.status == 200){
            this.user.firstName = this.fName;
            this.user.lastName = this.lName;
            this.user.email = this.email;
          }
        } catch (error) {
          this.updateResponse = error.response
          console.error(error);
        }
      },
    },
    computed:{
      ...mapState({
        appData: (state) => state.appData,
        isLoggedIn: (state) => state.isLoggedIn,
        user: (state) => state.user,
      }),
      darkMode() {
        return this.$vuetify.theme.dark;
      },
      ...mapState({
        appData: (state) => state.appData,
      }),
      canEdit(){
        return this.email != this.user.email 
          || this.fName != this.user.firstName
          || this.lName != this.user.lastName
      }
    }
  };
  </script>