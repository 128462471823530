import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import vuetify from "@/plugins/vuetify";
import VuePictureSwipe from "vue-picture-swipe";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "./assets/global.scss"; // Import the global CSS file
import VueCodemirror from 'vue-codemirror'

// require styles
import 'codemirror/lib/codemirror.css'

// require more codemirror resource...

// you can set default global options and events when use
Vue.use(VueCodemirror, /* { 
  options: { theme: 'base16-dark', ... },
  events: ['scroll', ...]
} */)

Vue.config.productionTip = false;
Vue.component("vue-picture-swipe", VuePictureSwipe);
store.dispatch("fetchAppData").then(() => {
  // Import and configure Vuetify with the data from the store
  import("@/plugins/vuetify").then((vuetifyModule) => {
    const vuetify = vuetifyModule.default;
    vuetify.framework.theme.themes.light =
      store.state.appData.theme.light.colors;
    vuetify.framework.theme.themes.dark = store.state.appData.theme.dark.colors;

    // Create the Vue instance with Vuetify
    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  });
});

Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (
    msg.includes(
      "Duplicate keys detected: 'warning'. This may cause an update error."
    )
  ) {
    // Filter out the specific warning you want to suppress
    return;
  }
  if (
    msg.includes(
      "Duplicate keys detected: 'info'. This may cause an update error."
    )
  ) {
    // Filter out the specific warning you want to suppress
    return;
  }
  if (
    msg.includes(
      "Duplicate keys detected: 'success'. This may cause an update error."
    )
  ) {
    // Filter out the specific warning you want to suppress
    return;
  }
  if (
    msg.includes(
      "Duplicate keys detected: 'error'. This may cause an update error."
    )
  ) {
    // Filter out the specific warning you want to suppress
    return;
  }
  if (
    msg.includes(
      "Duplicate keys detected: 'accent'. This may cause an update error."
    )
  ) {
    // Filter out the specific warning you want to suppress
    return;
  }
  if (
    msg.includes(
      "Duplicate keys detected: 'secondary'. This may cause an update error."
    )
  ) {
    // Filter out the specific warning you want to suppress
    return;
  }
  if (
    msg.includes(
      "Duplicate keys detected: 'primary'. This may cause an update error."
    )
  ) {
    // Filter out the specific warning you want to suppress
    return;
  }

  // Default behavior for other warnings
  console.warn(msg, vm, trace);
};

// new Vue({
//   router,
//   store,
//   async created() {
//     const appData = store.getters['getAppData']
//     console.log(appData)
//     if (!appData) {
//       await store.dispatch('fetchAppData');
//     }
//     const vuetify = await import("@/plugins/vuetify");
//     console.log("VUETIFY",vuetify)
//     return vuetify.default;
//   },
//   // vuetify,
//   render: (h) => h(App),
// }).$mount("#app");
