<template>
  <div id='cartView'>
    <v-container style="max-width: 1620px;">
      <v-breadcrumbs class="pb-10" :items="breadcrums"></v-breadcrumbs>
      <!-- <v-breadcrumbs  :items="breadcrumbs"></v-breadcrumbs> -->

        <v-card class="rounded-card mt-0">
          <v-row style="flex-wrap: wrap-reverse;">
            <v-col
              :cols="12"
              md="9"
              sm="12"
              style="padding-top: 0; padding-bottom: 0; width:0px;"
            >
              <v-simple-table style="padding: 20px;">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">ITEM</th>
                      <th class="text-center">PRICE</th>
                      <th class="text-center">QUANTITY</th>
                      <th class="text-center">TOTAL</th>
                      <th class="text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="cartItem in cartItems">
                      <tr v-bind:key="cartItem">
                        <td>
                          <v-list-item
                            key="1"
                            @click.prevent=""
                            :to="`/product?id=${cartItem.id}`"
                          >
                            <v-list-item-avatar>
                              <v-img :src="cartItem.imgUrl"></v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title>{{
                                cartItem.name
                              }}</v-list-item-title>
                              <v-list-item-subtitle>
                                <!-- {{ cartItem.description }} -->
                                <div
                                  v-for="(meta, i) of cartItem.metadataGroups"
                                  :key="meta.id"
                                >
                                  {{
                                    meta.metadataOptions.find(
                                      (o) => o.id == meta.defaultOptionId
                                    )?.title
                                  }}
                                  {{ meta.length == i + 1 ? "," : "" }}
                                </div>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </td>
                        <td>
                          ${{
                            (
                              cartItem.price -
                              cartItem.price * cartItem.salePctOff +
                              selectedMetadataOptionsTotal(cartItem)
                            ).toFixed(2)
                          }}
                        </td>
                        <td>
                          <v-text-field
                            class="pt-10"
                            label="Qty"
                            style="width: 80px"
                            single-line
                            @input="changeQty($event, cartItem.id)"
                            outlined
                            :value="cartItem.qty"
                            type="number"
                            :min="0"
                          ></v-text-field>
                        </td>
                        <td>
                          ${{
                            (
                              (cartItem.price -
                                cartItem.price * cartItem.salePctOff +
                                selectedMetadataOptionsTotal(cartItem)) *
                              cartItem.qty
                            ).toFixed(2)
                          }}
                        </td>
                        <td>
                          <a @click.prevent="removeCartItem(cartItem.id)">X</a>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col :cols="12" md="3" sm="12">
              <p class="headline">Order Summary</p>
              <p class="overline">
                Shipping and additional costs are calculated based on values you
                have entered.
              </p>
              <v-simple-table style="text-align: left">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>Subtotal</td>
                      <td class="text-right" style="width: 50px">
                        {{ subtotal }}
                      </td>
                    </tr>
                    <tr>
                      <td>Shipping</td>
                      <td class="text-right" style="width: 50px">
                        {{ shipping }}
                      </td>
                    </tr>
                    <tr>
                      <td>Tax</td>
                      <td class="text-right" style="width: 50px">{{ tax }}</td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td class="text-right" style="width: 50px">
                        <b>{{ total }}</b>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div class="text-center">
                <v-btn to="/checkout" rounded x-large class="primary white--text mt-5" outlined
                  >PROCEED TO CHECKOUT</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-card>

    </v-container>
    <v-card class="accent">
      <v-container>
        <v-row no-gutters>
          <v-col class="col-12 col-md-4 col-sm-12">
            <v-row>
              <v-col class="col-12 col-sm-3 pr-4 hidden-sm-only" align="right">
                <v-icon class="display-2">mdi-truck</v-icon>
              </v-col>
              <v-col class="col-12 col-sm-9 pr-4">
                <h3 class="font-weight-light">FREE SHIPPING & RETURN</h3>
                <p class="font-weight-thin">Free Shipping over $300</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="col-12 col-md-4 col-sm-12">
            <v-row>
              <v-col class="col-12 col-sm-3 pr-4" align="right">
                <v-icon class="display-2">mdi-cash-usd</v-icon>
              </v-col>
              <v-col class="col-12 col-sm-9 pr-4">
                <h3 class="font-weight-light">MONEY BACK GUARANTEE</h3>
                <p class="font-weight-thin">30 Days Money Back Guarantee</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="col-12 col-md-4 col-sm-12">
            <v-row>
              <v-col class="col-12 col-sm-3 pr-4" align="right">
                <v-icon class="display-2">mdi-headset</v-icon>
              </v-col>
              <v-col class="col-12 col-sm-9 pr-4">
                <h3 class="font-weight-light">020-800-456-747</h3>
                <p class="font-weight-thin">24/7 Available Support</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<style lang="scss">
  #cartView{
    @media screen and (max-width: 959px) {
      .rounded-card{
        margin:0px!important;
      }
    }
    .rounded-card{
      margin: 30px;
      padding: 30px;
    }
  }

</style>
  <script>
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  data: () => ({
    rating: 4.5,
    breadcrums: [
      {
        text: "Home",
        disabled: false,
        to: "/",
      },
      {
        text: "Shop",
        disabled: false,
        to: "shop",
      },
      {
        text: "Cart",
        disabled: true,
        to: "cart",
      },
    ],
  }),
  computed: {
    ...mapState({
      // appData: (state) => state.appData,
      user: (state) => state.user,
    }),
    ...mapGetters({
      numberOfCartItems: "getNumberOfCartItems",
      cartItems: "getCartItems",
    }),
    subtotal() {
      let total = 0;
      this.cartItems.forEach((cartItem) => {
        console.log(cartItem.price);
        console.log(cartItem.salePctOff);
        console.log(this.selectedMetadataOptionsTotal(cartItem));

        const itemTotal =
          (cartItem?.price -
            cartItem?.price * cartItem?.salePctOff +
            this.selectedMetadataOptionsTotal(cartItem)) *
          cartItem.qty;
        total += parseFloat(itemTotal.toFixed(2));
      });
      return total.toFixed(2);
    },
    shipping() {
      return 0.0;
    },
    tax() {
      return (
        (parseFloat(this.subtotal) + parseFloat(this.shipping)) *
        0.13
      ).toFixed(2);
    },
    total() {
      return (
        (parseFloat(this.subtotal) + parseFloat(this.shipping)) *
        1.13
      ).toFixed(2);
    },
  },
  methods: {
    ...mapMutations([
      "incrementCartItemQty",
      "decrementCartItemQty",
      "addCartItem",
      "removeCartItem",
    ]),
    selectedMetadataOptionsTotal(cartItem) {
      let total = 0;
      console.log("cartItem", cartItem);
      cartItem?.metadataGroups?.forEach((meta) => {
        total += parseFloat(
          meta.metadataOptions.find(
            (option) => option.id == meta.defaultOptionId
          )?.price ?? 0
        );
      });
      return parseFloat(total.toFixed(2));
    },
    changeQty(newQty, cartItemId) {
      //not working yet
      let cartItem = this.cartItems.find((ci) => ci.id == cartItemId);
      if (newQty == 0) {
        return this.removeCartItem(cartItemId);
      }
      if (newQty > cartItem.qty) {
        while (cartItem.qty != newQty) {
          this.incrementCartItemQty(cartItemId);
        }
      } else if (newQty < cartItem.qty) {
        while (cartItem.qty != newQty) {
          this.decrementCartItemQty(cartItemId);
        }
      }
    },
  },
  mounted() {
    // console.log("cartitems", this.cartItems)
  },
};
</script>
  
  