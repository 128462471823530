<template>
    <v-card>
            <v-toolbar color="secondary" 
              :light="!themedTextColor('secondary')"
              :dark="themedTextColor('secondary')
              ">
              <v-icon  @click="collapsed = !collapsed" style="cursor:pointer">{{ collapsed ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
              <v-toolbar-title  @click="collapsed = !collapsed" style="cursor:pointer">{{ title }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field v-if="search" class="ml-auto" @input="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
              <v-btn v-if="handlePlus"
                icon
                @click="handlePlus"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-toolbar>
            <v-expand-transition>
              <div v-show="!collapsed">
                <v-card-text>
                    <slot></slot>
                </v-card-text>
              </div>
           </v-expand-transition>
          </v-card>
</template>
<script>
import { mapState} from "vuex";
export default {
    props:["title", "handlePlus", "search"],
    data(){
        return{
            collapsed: false,
        }
    },
    methods:{
      isDarkTheme() {
          return this.$vuetify.theme.dark;
      },
      themedTextColor(colorTheme){
          const darkTheme = this.isDarkTheme();
          let override = colorTheme == 'primary' ? 'invertPrimaryText' : 'invertSecondaryText' 
          const invertOverride = this.appData.theme[darkTheme ? 'dark' : 'light'][override];

          return invertOverride
              ? darkTheme
                  ? false
                  : true
              : darkTheme
                  ? true
                  : false
      },
    },
    computed: {
      ...mapState({
        appData: (state) => state.appData,
      }),
    }
}
</script>