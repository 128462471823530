<template>
    <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="editedItem.name"
                label="Title"
                @input="rerender"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                v-model="editedItem.code"
                label="Code"
                @input="rerender"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-checkbox
                v-model="savingsOverride"
                label="Savings Override"
              ></v-checkbox>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field v-if="!savingsOverride"
                v-model="editedItem.salePctOff"
                label="Sale Percent Off"
                type="number"
                step="0.01"
                @input="rerender"
                :rules="[percentRule]"
                :mask="percentMask"
                :disabled="savingsOverride"
              ></v-text-field>
              <v-text-field v-if="savingsOverride"
                v-model="editedItem.savings"
                label="Savings"
                type="number"
                step="0.01"
                @input="rerender"
                :rules="[numberRule]"
                :mask="mask"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-select v-if="isPublic"
                  label="Claimed Status"
                  persistent-hint
                  outlined
                  :items="[{name:'PUBLIC', value:'PUBLIC'},{name:'INACTIVE', value:'INACTIVE'}]"
                  :item-value="(s)=>s.value"
                  item-text="name"
                  v-model="editedItem.status"
              ></v-select>
              <v-select v-else
                  label="Claimed Status"
                  persistent-hint
                  outlined
                  default=""
                  :items="[{name:'CLAIMED', value:'CLAIMED'},{name:'UNCLAIMED', value:'UNCLAIMED'}]"
                  :item-value="(s)=>s.value"
                  item-text="name"
                  v-model="editedItem.status"
              ></v-select>

            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="isPublic==false">
              <UserSelector title="Select a User" :default="editedItem.userId" @Input="(v)=>{editedItem.userId = v; rerender();}"></UserSelector>
            </v-col>
          </v-row>
          <v-row>
            <v-col style="text-align: left;">
              <v-label>Promotion Expiry</v-label>
              <v-date-picker v-model="editedItem.expiry"></v-date-picker>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
        <v-btn color="primary" @click="save"> {{ formSubmitTitle }} </v-btn>
      </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
import Collapsable from '../collapsable.vue';
import UserSelector from '../selectors/userSelector.vue';

export default {
    props: {
        save: {
            type: Function,
            required: true
        },
        close: {
            type: Function,
            required: true
        },
        dialog: {
            type: Boolean,
            required: true
        },
        editedItem: {
            type: Object,
            required: true
        },
        editedIndex: {
            type: Number,
            required: true
        },
        isPublic:{
          type: Boolean,
          required: false
        }
    },
    data() {
        return {
            savingsOverride: false,
            defaultItem: {},
            percentMask: "##0.00",
            mask: "###0.00",
            percentRule: v => {
              const value = Number(v);
              return (isNaN(value) || value < 0 || value > 100 || !/^\d+(\.\d{1,2})?$/.test(v)) ? "Invalid percent value" : true;
            },
            numberRule: v => {
              const value = Number(v);
              return (isNaN(value) || value < 0 || value > 300 || !/^\d+(\.\d{1,2})?$/.test(v)) ? "Invalid price value, must be 0$ - $300" : true;
            }
        };
    },
    watch: {
        dialog(state) {
            if (!state) {
                this.close();
            }
            
          this.editedItem.expiry = new Date(this.editedItem.expiry).toISOString().slice(0, 10);
          console.log("new date", this.editedItem.expiry)
        },
        savingsOverride(value){
          if (value)
            delete this.editedItem.salePctOff;
          else
            delete this.editedItem.savings;
        },
        editedItem(val){
          console.log("here", this.editedItem)
          if(val.salePctOff == '0.0' || val.salePctOff == 0.0){
            delete this.editedItem.salePctOff;
          }
          if(val.savings == '0.0' || val.savings == 0.0){
            delete this.editedItem.savings;
          }
        },
    },
    methods:{
      rerender() {
        this.$forceUpdate();
      },
    },
    beforeMount(){
      this.editedItem.status = this.isPublic ? 'PUBLIC' : 'UNCLAIMED'
    },
    computed: {
        defaultImgUrl() {
          return this.editedItem.imgUrl;
        },
        isEditing(){
          return !(this.editedIndex === -1);
        },
        formTitle() {
          return this.isEditing ? "Edit Promotion" : "New Promotion";
        },
        formSubmitTitle() {
          return this.isEditing ? "Save" : "Add New";
        },
    },
    components: { Collapsable, UserSelector}
}
</script>