<template>
    <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <p class="subtitle-1">
              {{description}}
            </p>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
        <v-btn rounded color="primary" @click="actionFn"> {{ actionBtnText }} </v-btn>
      </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>

export default {
    props: {
        dialog: {
          type: Boolean,
          required: true
        },
        actionFn: {
          type: Function,
          required: true
        },
        description: {
          type: String,
          required: true
        },
        formTitle:{
          type: String,
          required: true
        },
        actionBtnText: {
          type: String,
          required: true
        }
    },
    data() {
        return {
        };
    },
    watch: {
        dialog(state) {
            if (!state) {
                this.close();
            }
        },
    },
    methods:{
      rerender() {
        this.$forceUpdate();
      },
      close(){
        this.dialog = false;
      }
    },
}
</script>