<template>
    <v-container style="max-width: 1620px; min-height: 80vh;display: grid;">
     
    <div>
        <v-snackbar
            style="margin-bottom: 55px"
            v-model="snackbar"
            text
            outlined
            :color="saveResponse?.status == 200 || saveResponse?.status == 201? 'success' : 'error'"
            centered
            >
            {{
                (saveResponse?.status == 200 || saveResponse?.status == 201)
                ? "App Data was successfully saved"
                : "Something went wrong"
            }}
            <template v-slot:action="{ attrs }">
                <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                Close
                </v-btn>
            </template>
        </v-snackbar>

        <br />

        <v-container fluid>
            <v-row>
                <Collapsable title="Transactions" :search="(val)=>(this.$refs.transactionsTable.search = val)">
                    <Datatable ref="transactionsTable" :data="transactions" :bulkSelect="true" :headers="transactionsHeaders">

                        <template v-slot:bulkActions="slotProps">
                            <v-btn @click="()=>slotProps.selectedItems.forEach(item=>item)">Bulk Delete</v-btn>
                        </template>
                    </Datatable>
                </Collapsable>
            </v-row>
        </v-container>
    </div>

</v-container>
</template>

<script>
import api from "@/api";
import Collapsable from "@/components/collapsable.vue";
import Datatable from "@/components/datatable.vue";

import PromosDialog from "@/components/dialogs/promosDialog.vue";
export default {
    data(){
        return {
            snackbar: false,
            saveResponse: null,
            transactionsHeaders: [
                {
                text: 'Transaction ID',
                align: 'start',
                value: 'id',
                },
                { text: 'Order ID', value: 'orderId'},
                { text: 'Payment Status', value: 'paymentStatus'},
                { text: 'Promo Code', value: 'promotion.code'},
                { text: 'Subtotal', value: 'subtotal'},
                { text: 'Discounted Amount', value: 'discountedAmount'},
                { text: 'Tax', value: 'tax'},
                { text: 'Shipping', value: 'shipping'},
                { text: 'Total', value: 'totalAmount'},
                { text: 'Transaction Date', value: 'transactionDate'},
            ],
            transactions: [],
        }
    },
    methods:{

        reviver(key, value) {
            // Check if the value is a string that represents a number
            if (/^-?\d+(\.\d+)?$/.test(value)) {
                // Check if the value is a decimal number or a whole number
                if (/^-?\d+\.\d+$/.test(value)) {
                    // Convert the string value to a decimal number
                    return parseFloat(value);
                } else {
                    // Convert the string value to an integer
                    return parseInt(value);
                }
            }
            return value;
        },
        fetchTransactions(){
            return api.get('/api/transaction').then(resp=>{
                this.transactions = resp.data.map(transaction=>{
                    transaction.totalAmount = (parseFloat(transaction.subtotal) + parseFloat(transaction.shipping) + parseFloat(transaction.tax) - parseFloat(transaction.discountedAmount)).toFixed(2)
                    return transaction;
                })
                return resp;
            }).catch(e=>{console.log(e); return e;})
        }
        // async updatePromo(editedItem){
        //     return api.put(`/api/promotion/${editedItem.id}`,JSON.parse(JSON.stringify(editedItem), this.reviver))
        //     .catch(e=>{console.log(e); return e.error;})
        // },
    },
    async beforeMount(){
        this.saveResponse = await this.fetchTransactions();
        console.log("transactions", this.transactions)

    },
    components: {
        // ShopView,
        Collapsable,
        Datatable,
        PromosDialog,
    }
}
</script>

<style>
.truncated {
  max-width: 200px; /* Set the maximum width for the cell */
  white-space: nowrap; /* Prevent line breaks */
  overflow: hidden; /* Hide any overflowing text */
  text-overflow: ellipsis; /* Add an ellipsis (...) to indicate truncated text */
}
</style>