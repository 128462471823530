<template>
    <v-container style="max-width: 1620px; min-height: 80vh;display: grid;">
     
    <div>
        <v-snackbar
            style="margin-bottom: 55px"
            v-model="snackbar"
            text
            outlined
            :color="saveResponse?.status == 200 || saveResponse?.status == 201? 'success' : 'error'"
            centered
            >
            {{
                (saveResponse?.status == 200 || saveResponse?.status == 201)
                ? "App Data was successfully saved"
                : "Something went wrong"
            }}
            <template v-slot:action="{ attrs }">
                <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                Close
                </v-btn>
            </template>
        </v-snackbar>

        <br />

        <v-container fluid>
            <v-row>
                <SendEmailDialog :email="email" :save="sendMail" :close="close" :dialog="dialog"></SendEmailDialog>
                            
                <Collapsable title="Newsletter" :search="(val)=>(this.$refs.newsletterTable.search = val)">
                    <Datatable ref="newsletterTable" :data="newsletter" :bulkSelect="true" :headers="newsletterHeaders">

                        <template v-slot:bulkActions="slotProps">
                            <v-btn @click="()=>{open(); email.recipients = slotProps.selectedItems.map(r=>({email:r.email, active: r.active}))}">Bulk Send Email</v-btn>
                        </template>

                        <template v-slot:extraAction="slotProps"> 
                            <v-icon title="deactivate" v-if="slotProps.editedItem.active" @click="deactivateSubscriber(slotProps.editedItem)">mdi-account-cancel</v-icon>
                            <v-icon title="activate" v-else @click="activateSubscriber(slotProps.editedItem)">mdi-account-check</v-icon>
                        </template>
                    </Datatable>
                </Collapsable>
                
            </v-row>
        </v-container>
    </div>

</v-container>
</template>
<script>
import api from "@/api";
import Collapsable from "@/components/collapsable.vue";
import Datatable from "@/components/datatable.vue";
import SendEmailDialog from "@/components/dialogs/sendEmailDialog.vue";

export default {
    data(){
        return {
            snackbar: false,
            saveResponse: null,
            newsletterHeaders: [
                {
                text: 'Email',
                align: 'start',
                value: 'email',
                },
                { text: 'Active', value: 'active'},
                { text: 'Actions', value: 'actions'},
            ],
            newsletter: [],
            email: {
                template: 'promo.hbs',
                subject: '',
                metadata: {
                    testprop: '',
                    anotherprop: [
                        {title: "item1"}
                    ]
                },
                recipients: []
            },
            dialog: false
        }
    },
    methods:{

        reviver(key, value) {
            // Check if the value is a string that represents a number
            if (/^-?\d+(\.\d+)?$/.test(value)) {
                // Check if the value is a decimal number or a whole number
                if (/^-?\d+\.\d+$/.test(value)) {
                    // Convert the string value to a decimal number
                    return parseFloat(value);
                } else {
                    // Convert the string value to an integer
                    return parseInt(value);
                }
            }
            return value;
        },
        fetchNewsletter(){
            return api.get('/api/mail/newsletter').then(resp=>{
                this.newsletter = resp.data
                return resp;
            }).catch(e=>{console.log(e); return e;})
        },
        async sendMail(){
            this.saveResponse = await api.post(`/api/mail/send-mail/`,{...this.email, metadata: JSON.parse(this.email.metadata)})
            .catch(e=>{console.log(e); return e;})
            this.snackbar = true;
        },
        open(){
            this.dialog = true;
        },
        close(){
            this.email.metadata = JSON.parse(this.email.metadata);
            this.dialog = false;
        },
        async deactivateSubscriber(subscriber){
            this.saveResponse = await api.get(`/api/mail/leave-newsletter/${subscriber.emailHash}`)
            .catch(e=>{console.log(e); return e;})
            this.snackbar = true;
            subscriber.active = false;
        },
        async activateSubscriber(subscriber){
            this.saveResponse = await api.post(`/api/mail/rejoin-newsletter`,{email: subscriber.email})
            .catch(e=>{console.log(e); return e;})
            this.snackbar = true;
            subscriber.active = true;
        },
    },
    async beforeMount(){
        this.saveResponse = await this.fetchNewsletter();
        console.log("newsletter", this.newsletter)

    },
    components: {
        Collapsable,
        Datatable,
        SendEmailDialog
    }
}
</script>

<style>
.truncated {
  max-width: 200px; /* Set the maximum width for the cell */
  white-space: nowrap; /* Prevent line breaks */
  overflow: hidden; /* Hide any overflowing text */
  text-overflow: ellipsis; /* Add an ellipsis (...) to indicate truncated text */
}
</style>